import React, { useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  window: {
    overflowY: "scroll",
    scrollbarWidth: "none",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 1),

    "& > *": {
      padding: theme.spacing(0, 3),
      lineHeight: `${theme.mixins.toolbar.minHeight}px`,
      transition: theme.transitions.create(),
      "&:hover": {
        color: theme.palette.text.primary,
      },
    },
  },
}));

/**
 * Renders window component with overflows.
 * @param {Object} props props
 * @param {number} props.maxHeight max window height
 * @param {string} props.className class name
 * @param {JSX.Element|Array<JSX.Element>} props.children children
 * @returns {JSX.Element} component
 */
export default function Window({ maxHeight, className, children }) {
  const [atTop, setAtTop] = useState(true);
  const [atBottom, setAtBottom] = useState(true);
  const window = useRef();
  const classes = useStyles();
  const theme = useTheme();

  // initial update when no overflow
  useEffect(() => {
    setAtBottom(window.current.clientHeight === window.current.scrollHeight);
  }, []);

  function handleScroll(event) {
    const newAtTop = event.target.scrollTop === 0;
    const newAtBottom = window.current.clientHeight + event.target.scrollTop === window.current.scrollHeight;
    setAtTop(newAtTop);
    setAtBottom(newAtBottom);
  }

  return (
    <Box className={className}>
      <Box style={{ minHeight: theme.spacing(4), textAlign: "center" }}>
        { !atTop && <ExpandLessIcon /> }
      </Box>
      <Box
        ref={window}
        onScroll={handleScroll}
        classes={{ root: classes.window }}
        style={{ maxHeight }}
      >
        <Box classes={{ root: classes.container }}>
          { children }
        </Box>
      </Box>
      <Box style={{ minHeight: theme.spacing(4), textAlign: "center" }}>
        { !atBottom && <ExpandMoreIcon /> }
      </Box>
    </Box>
  );
}

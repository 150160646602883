import { APIError, NetworkError } from "constants/errors";

export const STATUSES = {
  API_DOWN: -2,
  NOT_FETCHED_YET: -1,
  PAUSED: 0,
  NOT_CHECKED_YET: 1,
  UP: 2,
  SEEMS_DOWN: 8,
  DOWN: 9,
};

/**
 * Get server status.
 * @param {string} apiKey api key
 * @returns {number} status
 */
export async function getStatus(apiKey) {
  const data = await getMonitors(apiKey);
  if (data.stat === "fail") {
    throw new APIError("No records were returned");
  }

  const { monitors } = data;
  if (monitors.length > 1) {
    throw new TypeError("Multiple monitors were returned (API key is not monitor-specific)");
  }

  const monitor = monitors.shift();
  const { status } = monitor;
  return status;
}

/**
 * Get getMonitors fetch request response.
 * @param {string} apiKey api key
 * @returns {Object} response
 */
async function getMonitors(apiKey) {
  const payload = {
    api_key: apiKey,
    format: "json",
    logs: 1,
  };
  const queryString = serialize(payload);
  const res = await fetch(`https://api.uptimerobot.com/v2/getMonitors?${queryString}`, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: queryString,
  });

  if (!res.ok) {
    throw new NetworkError();
  }

  return res.json();
}

/**
 * Serializes objects to query string.
 * @param {Object} object object
 * @returns {string} query string
 */
function serialize(object) {
  return Object.entries(object)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
}

import React from "react";
import { defaultsDeep } from "lodash";
import { Box, Fade } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

import Footer from "./Footer";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.mixins.toolbar.minHeight,
    minHeight: "100vh",
    margin: "0 auto",
    overflow: "hidden",
    wordBreak: "break-word",
  },
  hero: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

/**
 * Render page layout.
 * @param {Object} props
 * @param {boolean} [props.hero=false] whether to apply hero styling
 * @param {boolean} [props.fade=true] whether to apply fade transition
 * @param {string} [props.maxWidth] theme override for container default max width prop
 * @param {JSX.Element|Array<JSX.Element>} props.children children
 * @returns {JSX.Element} component
 */
export default function Layout({
  hero = false,
  fade = true,
  maxWidth,
  children,
}) {
  const classes = useStyles();
  let inner = (
    <Box classes={{ root: hero && classes.hero }}>
      { children }
    </Box>
  );
  if (fade) {
    inner = (<Fade in>{ inner }</Fade>);
  }

  /**
   * Provides overrided theme.
   * @param {Object} theme default theme object
   */
  function themeOverride(theme) {
    return defaultsDeep({
      props: {
        MuiContainer: { maxWidth },
      },
    }, theme);
  }

  return (
    <>
      <ThemeProvider theme={themeOverride}>
        <Box classes={{ root: classes.wrapper }}>
          <Header />
          { inner }
        </Box>
        <Footer />
      </ThemeProvider>
    </>
  );
}

import React from "react";
import { SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  primary: { fill: "#ee3625" },
  secondary: { fill: "#ffffff" },
}));

/**
 * Render Semtek Division icon.
 * @param {Object} props props
 * @param {string} [props.fontSize] font size
 * @param {string} [props.className] className
 * @returns {JSX.Element} component
 */
export default function Semtek({ fontSize, className }) {
  const staticClasses = useStyles();
  return (
    <SvgIcon
      alt="Semtek Division Logo"
      fontSize={fontSize}
      className={className}
    >
      <path
        className={staticClasses.primary}
        d="M12,18.8H4.6v-1.4H12
          c1.6,0,3-1.3,3-3s-1.3-3-3-3H8.3
          c-1.2,0-2.3-0.5-3.1-1.3
          C4.4,9.3,3.9,8.1,3.9,7
          c0-1.2,0.5-2.3,1.3-3.1
          C6.1,3,7.2,2.5,8.3,2.5h7.4V4H8.3
          c-1.6,0-3,1.3-3,3
          c0,1.6,1.3,3,3,3H12
          c1.2,0,2.3,0.5,3.1,1.3
          c0.8,0.8,1.3,1.9,1.3,3.1s-0.5,2.3-1.3,3.1
          C14.3,18.3,13.2,18.8,12,18.8z"
      />
      <rect className={staticClasses.secondary} x="9.5" y="12.5" width="1.4" height="3.7" />
      <path
        className={staticClasses.secondary}
        d="M12,22.5H9.5v-2.6h1.4V21H12c3.7,0,6.7-3,6.7-6.7
          c0-3.7-3-6.7-6.7-6.7h-1.1v1.1H9.5V6.2H12
          c1.1,0,2.2,0.2,3.2,0.6
          c1,0.4,1.8,1,2.6,1.7
          c0.7,0.7,1.3,1.6,1.7,2.6
          c0.4,1,0.6,2.1,0.6,3.2
          c0,1.1-0.2,2.2-0.6,3.2
          c-0.4,1-1,1.8-1.7,2.6
          c-0.7,0.7-1.6,1.3-2.6,1.7
          C14.2,22.3,13.1,22.5,12,22.5z"
      />
      <path
        className={staticClasses.primary}
        d="M2.4,12.5
          c0,0-0.4,2.5,0,5.3
          c0.4,2.9,2.4,4.4,2.4,4.4S0.1,22.7,0,19.5
          C-0.1,16.6,2.4,12.5,2.4,12.5z"
      />
      <path
        className={staticClasses.primary}
        d="M19.2,1.5
          c0,0-1.9,3.4,0.6,5.1
          c3,2.1,2.7,6,2.7,6s3.3-4.3,0.1-6.7
          C19,3.2,19.2,1.5,19.2,1.5z"
      />
    </SvgIcon>
  );
}

import React from "react";
import Navbar from "./Navbar";

/**
 * Render page header.
 * @returns {JSX.Element} component
 */
export default function Header() {
  return <Navbar />;
}

import React, {
  useCallback, useEffect, useRef, useState,
} from "react";
import { IconButton } from "gatsby-theme-material-ui";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import {
  CheckCircle as CheckIcon,
  Error as ErrorIcon,
  Launch as LaunchIcon,
  OfflineBolt as OfflineIcon,
  PauseCircleFilled as PauseIcon,
  PlayCircleFilled as StartIcon,
} from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { getStatus, STATUSES } from "apis/UptimeRobot";

const useStyles = makeStyles((theme) => {
  const spacing = theme.spacing(2);
  const width = theme.spacing(35);

  return {
    server: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",

      "&:not(:last-child)": {
        marginBottom: `${theme.spacing(2)}px !important`,
      },
      "& > *:not(:last-child)": {
        flex: `1 1 ${width}px`,
      },
    },
    serverInfo: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",

      "& > *": {
        flexBasis: width,
        flexGrow: 0,
        marginBottom: spacing,
      },
    },
    serverName: {
      display: "flex",
      alignItems: "center",

      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
    serverStatus: {
      display: "flex",
      marginBottom: spacing,

      "& > *:not(:last-child)": {
        marginRight: theme.spacing(1),
      },
    },
    disabled: { color: theme.palette.grey[600] },
    success: { color: theme.palette.success.main },
    warn: { color: theme.palette.warning.main },
    error: { color: theme.palette.error.main },
  };
});

const STATUS_TEXT = {
  [STATUSES.API_DOWN]: "Error",
  [STATUSES.NOT_FETCHED_YET]: "Refreshing",
  [STATUSES.PAUSED]: "Paused",
  [STATUSES.NOT_CHECKED_YET]: "Operational",
  [STATUSES.UP]: "Operational",
  [STATUSES.SEEMS_DOWN]: "Degraded",
  [STATUSES.DOWN]: "Down",
};

/**
 * Render server information and status.
 * @param {Object} props props
 * @param {string} props.name server name
 * @param {string} [props.href] server info link
 * @param {string} [props.address] server address
 * @param {string} props.apiKey monitor-specific api key
 * @param {number} props.overrideStatus override server status
 * @param {({type: string, payload: Object}) => void} props.dispatch action dispatcher for server statuses reducer
 * @returns {JSX.Element} component
 */
export default function Server({
  name, href, address,
  apiKey,
  overrideStatus,
  dispatch,
}) {
  const isMounted = useRef(true);
  const dispatchUpdate = useCallback((id, status) => {
    dispatch({
      type: "update",
      payload: { id, status },
    });
  }, [dispatch]);
  const [status, setStatus] = useState(STATUSES.NOT_FETCHED_YET);
  const updateStatus = useCallback(async () => {
    let newStatus;
    try {
      newStatus = overrideStatus || await getStatus(apiKey);
    } catch (error) {
      newStatus = STATUSES.API_DOWN;
    }
    if (isMounted.current) {
      setStatus(newStatus);
      dispatchUpdate(apiKey, newStatus);
    }
  }, [apiKey, overrideStatus, dispatchUpdate]);
  const classes = useStyles();

  // update status on mount and periodically
  useEffect(() => {
    updateStatus();
    const interval = setInterval(updateStatus, 60000);
    return () => {
      isMounted.current = false;
      clearInterval(interval);
    };
  }, [updateStatus]);

  return (
    <Box classes={{ root: classes.server }}>
      <Box classes={{ root: classes.serverInfo }}>
        <Box classes={{ root: classes.serverName }}>
          <Typography display="inline" variant="h4">
            { name }
          </Typography>
          { href
            && (
            <IconButton
              to={href}
              size="small"
              aria-label={`${name} information`}
            >
              <LaunchIcon fontSize="small" />
            </IconButton>
            ) }
        </Box>
        { address
          && (
          <Typography>
            { address }
          </Typography>
          ) }
      </Box>
      <ServerStatus status={overrideStatus || status} />
    </Box>
  );
}

/**
 * Render server status.
 * @param {Object} props props
 * @param {number} props.status server status
 * @returns {JSX.Element} component
 */
function ServerStatus({ status }) {
  const theme = useTheme();
  const classes = useStyles();

  const STATUS_ICONS = {
    [STATUSES.API_DOWN]: <OfflineIcon />,
    [STATUSES.NOT_FETCHED_YET]: <CircularProgress size={theme.spacing(3)} />,
    [STATUSES.PAUSED]: <PauseIcon />,
    [STATUSES.NOT_CHECKED_YET]: <StartIcon />,
    [STATUSES.UP]: <CheckIcon />,
    [STATUSES.SEEMS_DOWN]: <ErrorIcon />,
    [STATUSES.DOWN]: <ErrorIcon />,
  };
  const STATUS_CLASSES = {
    [STATUSES.API_DOWN]: classes.error,
    [STATUSES.NOT_FETCHED_YET]: classes.disabled,
    [STATUSES.PAUSED]: classes.disabled,
    [STATUSES.NOT_CHECKED_YET]: classes.success,
    [STATUSES.UP]: classes.success,
    [STATUSES.SEEMS_DOWN]: classes.warn,
    [STATUSES.DOWN]: classes.error,
  };

  return (
    <Box classes={{ root: `${classes.serverStatus} ${STATUS_CLASSES[status]}` }}>
      <Typography>
        { STATUS_TEXT[status] }
      </Typography>
      { STATUS_ICONS[status] }
    </Box>
  );
}

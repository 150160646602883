import React from "react";
import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  box: {
    flex: "1 1 auto",
    textAlign: "center",

    "& > *:not(:last-child)": {
      margin: theme.spacing(0, 0, 10),
    },
  },
  container: {
    padding: theme.spacing(10, 5),

    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(5),
    },
  },
  smallSpacing: {
    "&:not(:first-child)": {
      paddingTop: theme.spacing(5),
    },
    "&:not(:last-child)": {
      paddingBottom: theme.spacing(5),
    },
  },
  mediumSpacing: {
    "&:not(:first-child)": {
      paddingTop: theme.spacing(10),
    },
    "&:not(:last-child)": {
      paddingBottom: theme.spacing(10),
    },
  },
  hero: {
    display: "flex",
    alignItems: "center",
  },
}));

const CONTAINER_MAX_WIDTH = {
  extraSmall: "xs",
  small: "sm",
  medium: "md",
  large: "lg",
  extraLarge: "xl",
};

/**
 * Wrap children into container with padding.
 * @param {Object} props props
 * @param {boolean} [props.hero=false] whether to apply hero styling
 * @param {string} [props.id] id
 * @param {string} [props.spacing="medium"] size of spacing padding
 * @param {string} [props.maxWidth] container max width
 * @param {string} [props.wrapperClasses] wrapper classes
 * @param {string} [props.className] className
 * @param {Object} [props.style] style
 * @param {JSX.Element|Array<JSX.Element>} props.children children
 * @returns {JSX.Element} component
 */
export default function Section({
  hero = false,
  id,
  spacing = "medium",
  maxWidth,
  wrapperClasses,
  className, style, children,
}) {
  const classes = useStyles();

  let containerClasses = classes.container;
  let wrappedChildren = children;
  if (hero) {
    containerClasses += ` ${classes.hero}`;
    wrappedChildren = (
      <Box classes={{ root: classes.box }}>
        { wrappedChildren }
      </Box>
    );
  }

  switch (spacing) {
    case "small": {
      containerClasses += ` ${classes.smallSpacing}`;
      break;
    }
    case "medium":
    default: {
      containerClasses += ` ${classes.mediumSpacing}`;
      break;
    }
  }

  return (
    <Box classes={wrapperClasses}>
      <Container
        id={id}
        component="section"
        maxWidth={maxWidth && CONTAINER_MAX_WIDTH[maxWidth]}
        classes={{ root: containerClasses }}
        className={className}
        style={style}
      >
        { wrappedChildren }
      </Container>
    </Box>
  );
}

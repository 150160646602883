import React, { useEffect, useState } from "react";
import { IconButton as GatsbyIconButton, Link } from "gatsby-theme-material-ui";
import {
  AppBar,
  Box,
  Container,
  Collapse,
  IconButton,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core";
import { Close as CloseIcon, Menu as MenuIcon } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Semtek as SemtekIcon } from "icons";

const useStyles = makeStyles((theme) => ({
  collapseBox: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  link: {
    display: "inline-block",
    height: theme.mixins.toolbar.minHeight,
    padding: theme.spacing(0, 3),
    lineHeight: `${theme.mixins.toolbar.minHeight}px`,
    transition: theme.transitions.create(),

    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  linkActive: {
    color: `${theme.palette.text.disabled} !important`,
  },
  nav: {
    display: "flex",
    flexDirection: "column",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  verticalMenuList: {
    flex: "1 1 auto",
    overflowY: "auto",
    paddingTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const LINK_DATA = {
  Home: "/",
  Tools: "/tools",
  Status: "/status",
  Join: "/join",
};

/**
 * Render website navigation.
 * @returns {JSX.Element} component
 */
export default function Navbar() {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  // disable body overflow if menu open
  useEffect(() => {
    const overflow = open ? "hidden" : "unset";
    document.body.style.overflow = overflow;
  }, [open]);

  function handleClick() { setOpen(!open); }
  function handleClose() { setOpen(false); }

  const menuIcon = (
    <IconButton
      aria-haspopup="true"
      aria-label="Menu"
      onClick={handleClick}
    >
      { open ? <CloseIcon /> : <MenuIcon />}
    </IconButton>
  );

  return (
    <AppBar position="fixed" elevation={0}>
      <Collapse
        collapsedHeight={theme.mixins.toolbar.minHeight}
        in={mobile && open}
        timeout={500}
      >
        <Box classes={{ root: classes.collapseBox }}>
          <Container>
            <Toolbar classes={{ root: classes.toolbar }}>
              <GatsbyIconButton to="/" aria-label="Semtek Division">
                <SemtekIcon />
              </GatsbyIconButton>
              { !mobile
                && (
                <MenuList
                  links={LINK_DATA}
                  onClick={handleClose}
                />
                ) }
              { mobile && menuIcon }
            </Toolbar>
          </Container>
          { mobile
            && (
            <MenuList
              open={mobile && open}
              links={LINK_DATA}
              vertical
              classes={{
                nav: classes.nav,
              }}
              onClick={handleClose}
            />
            ) }
        </Box>
      </Collapse>
    </AppBar>
  );
}

/**
 * Renders menu link list.
 * @param {Object} props props
 * @param {boolean} [props.open=true] open state
 * @param {Record<string, string>} props.links list of link display name and hrefs
 * @param {boolean} props.container whether to wrap the component with a container
 * @param {Record<string, string>} props.classes classes
 * @param {() => void} props.onClick onClick callback
 * @returns {JSX.Element} component
 */
function MenuList({
  open = true, links, vertical, classes = {}, onClick,
}) {
  const staticClasses = useStyles();

  const menuItems = Object.entries(links)
    .map(([display, href]) => (
      <NavbarLink
        key={href}
        href={href}
        hidden={!open}
        onClick={onClick}
      >
        { display }
      </NavbarLink>
    ));

  let menuList;
  if (vertical) {
    menuList = (
      <Container classes={{ root: staticClasses.verticalMenuList }}>
        <nav
          aria-hidden={!open}
          className={classes.nav}
          style={{ visibility: open ? "visible" : "hidden" }}
        >
          { menuItems }
        </nav>
      </Container>
    );
  } else {
    menuList = (
      <nav className={classes.nav}>
        { menuItems }
      </nav>
    );
  }

  return menuList;
}

/**
 * Render navigation link.
 * Hidden prop is workaround for Jest JSDOM limitation of not detecting children "visibility"
 * @param {Object} props props
 * @param {string} props.href link href
 * @param {boolean} props.hidden hidden state
 * @param {string} props.className className
 * @param {Object} [props.style] style
 * @param {() => void} props.onClick onClick callback
 * @param {JSX.Element|Array<JSX.Element>} props.children children
 * @returns {JSX.Element} component
 */
function NavbarLink({
  href, hidden, className, style, onClick, children,
}) {
  const classes = useStyles();
  return (
    <Link
      to={href}
      activeClassName={classes.linkActive}
      color="textSecondary"
      underline="none"
      aria-hidden={hidden}
      classes={{ root: classes.link }}
      className={className}
      style={{ ...style, visibility: hidden ? "hidden" : "visible" }}
      onClick={onClick}
    >
      { children }
    </Link>
  );
}

import React from "react";
import { SvgIcon } from "@material-ui/core";

/**
 * Render Teamspeak icon.
 * @param {Object} props props
 * @param {string} [props.fontSize] font size
 * @param {string} [props.className] className
 * @returns {JSX.Element} component
 */
export default function Teamspeak({ fontSize, className }) {
  return (
    <SvgIcon
      alt="Teamspeak Logo"
      fontSize={fontSize}
      className={className}
    >
      <path d="M0,12.5
        c0-0.4,0-0.8,0-1.2
        c0.1-0.7,0.5-1.3,1.2-1.6
        c0.2-0.1,0.3-0.2,0.3-0.4
        c0.3-1.6,0.9-3,1.9-4.3
        c0.1-0.1,0.2-0.3,0.1-0.4
        c-0.2-0.2,0-0.5,0.1-0.7
        c1.3-1.5,2.9-2.6,4.8-3.1
        c4.5-1.4,8.5-0.4,11.8,2.8
        c0.3,0.3,0.7,0.6,0.3,1.1
        c-0.1,0.1,0.1,0.2,0.1,0.2
        c1,1.3,1.6,2.8,1.9,4.4
        c0,0.2,0.1,0.2,0.3,0.3
        c0.8,0.4,1.2,1.1,1.2,2
        c0,0.8,0.1,1.6-0.1,2.4
        c-0.2,1.2-1.4,2-2.5,1.7
        c-0.3-0.1-0.4-0.3-0.4-0.7
        c0-1.3,0-2.6,0-4
        c-0.1-3.6-1.7-6.2-4.8-7.9
        C11,0.4,4.3,3.5,3.2,9.4
        c-0.2,1-0.2,2-0.2,3.1
        c0,1,0,1.9,0,2.9
        c0,0.3-0.2,0.5-0.6,0.5
        C1,15.9,0,15,0,13.5
        C0,13.2,0,12.9,0,12.5"
      />
      <path d="M14.7,17.5
        c0.6,0.1,1.2,0.2,1.8,0.2
        c0.8,0,1.4-0.2,1.9-0.7
        c0.6-0.6,0.8-1.3,0.8-2.2
        c0-0.8-0.8-1.3-1.7-1.1
        c-0.9,0.1-1.6,0.5-2.4,0.8
        c-0.7,0.3-1.3,0.6-1.8,1.1
        c-0.7,0.7-0.3,1.5,0.8,1.8
        C14.3,17.4,14.5,17.4,14.7,17.5"
      />
      <path d="M11.3,15.8L11.3,15.8
        C11.3,15.8,11.3,15.8,11.3,15.8
        c-0.1-0.2-0.2-0.4-0.3-0.5
        c-0.4-0.7-1.1-1.3-1.7-1.8
        c-0.3-0.3-0.7-0.5-1-0.8
        c-0.8-0.6-1.4-0.9-1.4-0.9l0,0
        c-1-0.5-1.8,0-2.1,1.1
        c-0.3,1.2-0.1,2.4,0.6,3.5
        c0.5,0.7,1.2,1.1,2,1.2
        c0.5,0,1.1,0.1,1.6,0.1
        c0.4,0,0.9,0,1.3-0.1
        c0.6-0.2,1-0.5,1.1-1.1
        C11.4,16.1,11.4,15.9,11.3,15.8"
      />
      <path d="M21.6,18.6
        c0-0.5-0.2-1.3-0.5-1.5
        c-0.1-0.1-0.3,0-0.4,0.1
        c-0.1,0.2-0.2,0.5-0.3,0.9h0
        c-0.4,0.9-0.9,1.6-1.6,2.2l0,0
        c-0.8,0.8-1.8,1.3-2.8,1.7
        c0,0,0,0,0,0
        c-0.2,0.1-0.5,0.2-0.7,0.3
        c0,0,0,0,0,0
        c-0.2,0.1-0.3,0.1-0.5,0.1
        c0,0,0,0,0,0
        c-0.1,0-0.1,0-0.2,0.1
        c0,0,0,0,0,0
        c-0.1,0-0.3,0.1-0.4,0.1
        c0,0,0,0,0,0
        c-0.1,0-0.1,0-0.2,0
        c0,0,0,0,0,0
        c-0.1,0-0.3,0.1-0.4,0.1
        c0,0,0,0,0,0
        c-0.1,0-0.1,0-0.2,0
        c0,0,0,0,0,0
        c-0.1,0-0.2,0-0.3,0.1
        c0,0,0,0,0,0
        c0,0-0.1,0-0.1,0
        c0,0,0,0,0,0
        c-0.1,0-0.1,0-0.1,0
        c0,0,0,0,0,0
        c0,0-0.1,0-0.1,0
        c0,0,0,0,0,0
        c0,0-0.1,0-0.1,0
        c0,0,0,0,0,0l0,0
        c-0.3,0-0.5,0-0.5,0
        c0,0,0,0,0,0l0,0
        c-4.1,0.4,1,1.3,4.9,0.6l0,0
        c0,0,0,0,0,0
        c0.1,0,0.2,0,0.3-0.1
        c0.1,0,0.2,0,0.2,0
        c0.1,0,0.1,0,0.2,0
        c0,0,0.1,0,0.1,0
        c0.5-0.1,1-0.3,1.4-0.5
        c2.4-1.2,2.6-3.1,2.6-3.1
        C21.6,19.5,21.6,19.1,21.6,18.6"
      />
    </SvgIcon>
  );
}

import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router"; // eslint-disable-line import/no-extraneous-dependencies

export const query = graphql`
  query HeadMetadata {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        siteUrl
        defaultDescription: description
        defaultImage: image
      }
    }
  }
`;

/**
 * Render head metadata.
 * @param {Object} props props
 * @param {string} [props.title] page title
 * @param {string} [props.titleTemplate] page title template
 * @param {string} [props.description] page description
 * @param {string} [props.image] page image path
 * @param {string} [props.type="website"] page type
 * @returns {JSX.Element} component
 */
export default function Meta({
  title, titleTemplate,
  description,
  image,
  type = "website",
}) {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const { siteMetadata } = site;

  const seo = {
    title: title || siteMetadata.defaultTitle,
    url: `${siteMetadata.siteUrl}${pathname}`,
    description: description || siteMetadata.defaultDescription,
    image: `${siteMetadata.siteUrl}${image || siteMetadata.defaultImage}`,
  };

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={seo.title}
      titleTemplate={titleTemplate || siteMetadata.titleTemplate}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
    </Helmet>
  );
}

import React from "react";
import {
  Box, Container, Toolbar, Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.grey[900],
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    minHeight: theme.spacing(4),
  },
}));

/**
 * Render page footer.
 * @returns {JSX.Element} component
 */
export default function Footer() {
  const classes = useStyles();
  const year = new Date().getFullYear();
  return (
    <Box classes={{ root: classes.box }}>
      <Container>
        <Toolbar classes={{ root: classes.toolbar }}>
          <Typography
            color="textSecondary"
            align="right"
            variant="body2"
          >
            © Semtek Division
            {" "}
            { year }
          </Typography>
        </Toolbar>
      </Container>
    </Box>
  );
}
